import { Component, OnInit } from '@angular/core';
import { PageService } from '../../../services/page-service';

@Component({
  selector: 'app-section-wa',
  templateUrl: './section-wa.component.html',
  styleUrls: ['./section-wa.component.css']
})
export class SectionWaComponent implements OnInit {
  public showWa = false;
  public pagesProfileData: any = [];

  constructor(
    private pageService: PageService,
  ) { 
    this.fetchWaData()
  }

  ngOnInit() {
  }

  onShowWa(isShowWa: any) {
    if (isShowWa) {
      this.showWa = false;
    } else {
      this.showWa = true;
    }
  }

  redirectWa() {
    const waNumber = this.pagesProfileData[0].no_wa
    window.open(`https://api.whatsapp.com/send?phone=${waNumber}`, '_blank');
  }

  async fetchWaData() {
    const res = await this.pageService.pagesData('profile').toPromise();
    this.pagesProfileData.push(res.data);
  }

}
