import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.css']
})
export class SectionHeaderComponent implements OnInit {
  public isBottom = false;
  public homeActive = false;
  public aboutActive = false;
  public serviceActive = false;
  public teamActive = false;
  public navActive = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  @HostListener('window:scroll', [])
  async ngOnInit() {
    this.onScroll();
    const path: any = await new Promise(res =>
      this.route.url.subscribe((path: any) =>  res(path))
      );

    if (path)  {
      if (path.length === 0) {
        this.homeActive = true;
      } else if (path.length > 0 && path[0].path === 'about') {
        this.aboutActive = true;
      } else if (path.length > 0 && path[0].path === 'service') {
        this.serviceActive = true;
      } else if (path.length > 0 && path[0].path === 'team') {
        this.teamActive = true;
      } else if (path.length > 0 && path[0].path === 'lets-talk') {
        this.homeActive = true;
      } else if (path.length > 0 && path[0].path === 'recruitment') {
        this.serviceActive = true;
      } else if (path.length > 0 && path[0].path === 'outsource') {
        this.serviceActive = true;
      } else if (path.length > 0 && path[0].path === 'payroll') {
        this.serviceActive = true;
      } else if (path.length > 0 && path[0].path === 'general') {
        this.serviceActive = true;
      }
    }
  }

  onScroll(): void {
    if (window.scrollY >= 100) {
      this.isBottom = true;
    } else {
      this.isBottom = false;
    }
  }

  onNavigate(page: any) {
    this.router.navigate([`${page}`], { skipLocationChange: true });
  }

  showNavbar(isActive: any) {
    this.navActive = isActive;
  }

}
