import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-page-privacy-policy',
  templateUrl: './page-privacy-policy.component.html',
  styleUrls: ['./page-privacy-policy.component.css']
})
export class PagePrivacyPolicyComponent implements OnInit {

  constructor(private router: Router) {
    // subscribes every changes of your route
    this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
           // scroll to top
           window.scrollTo(0, 0);
        }
     });
  }

  ngOnInit() {
  }

}
