import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import { PageService } from '../../../services/page-service';

@Component({
  selector: 'app-page-about-us',
  templateUrl: './page-about-us.component.html',
  styleUrls: ['./page-about-us.component.css']
})
export class PageAboutUsComponent implements OnInit {
  pagesData: any = [];
  teamData: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private pageService: PageService,
  ) {
    // subscribes every changes of your route
    this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
           // scroll to top
           window.scrollTo(0, 0);
        }
     });

    this.fetchPageData();
    // this.fetchTeamData();
 }

  async ngOnInit() {
  }

  onNavigate(page: any) {
    this.router.navigate([`${page}`], { skipLocationChange: true });
  }

  async fetchPageData() {
    const res = await this.pageService.pagesData('aboutUs').toPromise();
    this.pagesData = res.data.data;

    setTimeout(async () => {
      const path: any = await new Promise(res =>
        this.route.url.subscribe((path: any) =>  res(path))
        );
  
      if (path)  {
        if (path.length > 0 && path[0].path === 'team') {
          const element = document.querySelector(`#team-section`);
          const elementPos = (element ? element.getBoundingClientRect().top : 0) + window.pageYOffset - (80 + 5);
          if (element) {
            window.scrollTo({behavior: 'smooth', top: elementPos});
          }
        }
      }
    }, 100);
  }

// async fetchTeamData() {
//   const res = await this.pageService.pagesData('ourTeams').toPromise();
//   this.teamData = res.data.data
//   console.log("🚀 ~ this.teamData:", this.teamData);
// }

}
