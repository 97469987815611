import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PageService } from '../../../services/page-service';

@Component({
  selector: 'app-page-recruitment',
  templateUrl: './page-recruitment.component.html',
  styleUrls: ['./page-recruitment.component.css']
})
export class PageRecruitmentComponent implements OnInit {
  pagesData: any = [];

  constructor(
    private router: Router,
    private pageService: PageService,
    private route: ActivatedRoute,
  ) {
    // subscribes every changes of your route
    this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
           // scroll to top
           window.scrollTo(0, 0);
        }
     });
     this.fetchPageData();
  }

  ngOnInit() {
  }

  async fetchPageData() {
    const res = await this.pageService.pagesData('services').toPromise();
    const path: any = await new Promise(res =>
      this.route.url.subscribe((path: any) =>  res(path))
      );

    if (path)  {
      if (path.length > 0 && path[0].path === 'recruitment') {
        this.pagesData = res.data.data.filter((item: any) => item.page_name === 'Recruitment & HeadHunting');
      } else if (path.length > 0 && path[0].path === 'general') {
        this.pagesData = res.data.data.filter((item: any) => item.page_name === 'General Service');
      }
    }
  }

}
