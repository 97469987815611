import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { NgbCarousel, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PageService } from '../../../services/page-service';
import { interval } from 'rxjs';

@Component({
  selector: 'app-page-home',
  templateUrl: './page-home.component.html',
  styleUrls: ['./page-home.component.css']
})
export class PageHomeComponent implements OnInit {

  @ViewChild('modalHover') modalHover: ElementRef;
  @ViewChild('XSlide') carousel: NgbCarousel;
  
  public pagesData: any = [];
  public carouselSlides1 = true;
  public carouselSlides2 = false;
  public carouselSlides3 = false;
  public activeCarousel = 1;
  public carouselImgLength: any = 0;
  modalHoverData: any;
  constructor(
    public modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private pageService: PageService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
         // scroll to top
         window.scrollTo(0, 0);
      }
    });
    interval(10000).subscribe(_x => {
      if(this.activeCarousel === 1) {
        this.setActiveCarousel(2);
      } else if(this.activeCarousel === 2) {
        this.setActiveCarousel(3);
      } else if(this.activeCarousel === 3) {
        this.setActiveCarousel(1);
      }
    });
    this.fetchPageData()
   }


  async ngOnInit() {
  }

  onHoverItem(item: any) {
    this.modalHoverData = item;
    const c = this.modalService.open(this.modalHover, {
      centered: true,
      windowClass: 'modal-confirm-notes',
    });
    c.result.then((_result: any) => {
    }).catch((_e) => {
    });
  }

  onNavigate(page: any) {
    this.router.navigate([`${page}`], { skipLocationChange: true });
  }

  onNavigateContent(page: any) {
    let route = '';
    switch (page) {
      case 'Outsourcing':
        route = '/outsource';
        break;
      case 'Recruitment & HeadHunting':
        route = '/recruitment';
        break;
      case 'Payroll Service':
        route = '/payroll';
        break;
      case 'General Service':
        route = '/general';
        break;
      case 'About Us':
        route = '/about';
        break;
      default:
        route = '/'
        break;
    }
    this.modalService.dismissAll();
    this.router.navigate([`${route}`], { skipLocationChange: true });
  }

  async fetchPageData() {
    const res = await this.pageService.pagesData('home').toPromise();
    this.pagesData = res.data.data;
    this.carouselImgLength = this.pagesData.length > 0 && this.pagesData[3] ? this.pagesData[3].detail.length : 0;
    
    setTimeout(async () => {
      const path: any = await new Promise(res =>
        this.route.url.subscribe((path: any) =>  res(path))
      );
      if (path)  {
        if (path.length > 0 && path[0].path === 'lets-talk') {
          const element = document.querySelector(`#section-chat`);
          const elementPos = (element ? element.getBoundingClientRect().top : 0) + window.pageYOffset - (80 + 5);
          if (element) {
            window.scrollTo({behavior: 'smooth', top: elementPos});
          }
        }
      }
    }, 100);
  }

  setActiveCarousel(item: any){
    if(this.carouselImgLength > 0) {
      const targetElement = document.getElementById('carouselImage');
      const elementScrollWidth = targetElement ? targetElement.scrollWidth : 0
      if (targetElement ? targetElement.scrollLeft !== elementScrollWidth : 0) {
        if(item === 1){
          this.carouselSlides1 = true;
          this.carouselSlides2 = false;
          this.carouselSlides3 = false;
          this.activeCarousel = 1;
          targetElement ? targetElement.scrollTo({behavior: 'smooth', left: targetElement.scrollLeft + -elementScrollWidth}) : 0
        }else if(item === 2){
          this.carouselSlides1 = false;
          this.carouselSlides2 = true;
          this.carouselSlides3 = false;
          this.activeCarousel = 2;
          targetElement ? targetElement.scrollTo({behavior: 'smooth', left: targetElement.scrollLeft + (elementScrollWidth/(this.carouselImgLength/2))}) : 0
        }else if(item === 3){
          this.carouselSlides1 = false;
          this.carouselSlides2 = false;
          this.carouselSlides3 = true;
          this.activeCarousel = 3;
          targetElement ? targetElement.scrollTo({behavior: 'smooth', left: targetElement.scrollLeft + elementScrollWidth }) : 0
        }
      }
    }
  }

}
