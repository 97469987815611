import { Component, OnInit } from '@angular/core';
import { PageService } from '../../../services/page-service';
import { showErrorAlert, showSuccessAlert } from '../../../utils/swal.util';

@Component({
  selector: 'app-section-chat',
  templateUrl: './section-chat.component.html',
  styleUrls: ['./section-chat.component.css']
})
export class SectionChatComponent implements OnInit {
  public custName: any = null;
  public custEmail: any = null;
  public custCompany: any = null;
  public custPhone: any = null;
  public custDesc: any = null;
  public custNameError: any = false;
  public custEmailError: any = false;
  public custCompanyError: any = false;
  public custPhoneError: any = false;
  public custDescError: any = false;

  constructor(
    private pageService: PageService,
  ) { }

  ngOnInit() {
  }

  async onSubmit() {
    this.custNameError = (this.custName === null || this.custName === "");
    this.custEmailError = (this.custEmail === null || this.custEmail === "");
    this.custCompanyError = (this.custCompany === null || this.custCompany === "");
    this.custPhoneError = (this.custPhone === null || this.custPhone === "");
    this.custDescError = (this.custDesc === null || this.custDesc === "");

    if((!this.custNameError && !this.custEmailError && !this.custCompanyError && !this.custPhoneError && !this.custDescError)){
      const payload = {
        company_name: this.custCompany,
        telephone: this.custPhone,
        email: this.custEmail,
        name: this.custName,
        message: this.custDesc
      }
  
      const response = await this.pageService.letsTalk(payload).toPromise();
      if(response.response_code === 200){
        showSuccessAlert("Thank you for your message. We will get back to you as soon as possible!")
      } else {
        showErrorAlert("Something went wrong. Please try again later.")
      }
  
      this.custName = null;
      this.custEmail = null;
      this.custCompany = null;
      this.custPhone = null;
      this.custDesc = null;
    }
  }

}
