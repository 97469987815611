import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PageService } from '../../../services/page-service';

@Component({
  selector: 'app-page-our-service',
  templateUrl: './page-our-service.component.html',
  styleUrls: ['./page-our-service.component.css']
})
export class PageOurServiceComponent implements OnInit {
  pagesData: any = [];

  constructor(
    private router: Router,
    private pageService: PageService
  ) {
    // subscribes every changes of your route
    this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
           // scroll to top
           window.scrollTo(0, 0);
        }
     });

    this.fetchPageData();
  }

  ngOnInit() {
  }

  async fetchPageData() {
    const res = await this.pageService.pagesData('services').toPromise();
    this.pagesData = res.data.data;
  }

  onNavigate(page: any) {
    let route = '';
    switch (page) {
      case 'Outsourcing':
        route = '/outsource';
        break;
      case 'Recruitment & HeadHunting':
        route = '/recruitment';
        break;
      case 'Payroll Service':
        route = '/payroll';
        break;
      case 'General Service':
        route = '/general';
        break;
      default:
        route = '/'
        break;
    }
    this.router.navigate([`${route}`], { skipLocationChange: true });
  }
}
