import { Component, OnInit } from '@angular/core';
import { PageService } from '../../../services/page-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-section-footer',
  templateUrl: './section-footer.component.html',
  styleUrls: ['./section-footer.component.css']
})
export class SectionFooterComponent implements OnInit {
  pagesData: any = [];

  constructor(
    private pageService: PageService,
    private router: Router,
  ) {
    this.fetchPageData();
  }

  ngOnInit() {
  }

  async fetchPageData() {
    const res = await this.pageService.pagesData('profile').toPromise();
    this.pagesData.push(res.data);
  }

  onNavigate(page: any) {
    this.router.navigate([`${page}`], { skipLocationChange: true });
  }

}
