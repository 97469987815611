import Swal from 'sweetalert2';

export const showErrorAlert = (message: string, timer = 1500, options = {}) => {
  Swal.fire({
    type: 'error',
    title: 'Oops...',
    text: message,
    timer,
    ...options,
  });
};

export const showSuccessAlert = (message: string, timer = 1500, options = {}) => {
  Swal.fire({
    type: 'success',
    title: 'Success',
    text: message,
    timer,
    ...options,
  });
}

export const alert = Swal;
