import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageHomeComponent } from './views/pages/page-home/page-home.component';
import { PageAboutUsComponent } from './views/pages/page-about-us/page-about-us.component';
import { PageOurServiceComponent } from './views/pages/page-our-service/page-our-service.component';
import { PageOutsourceComponent } from './views/pages/page-outsource/page-outsource.component';
import { PageRecruitmentComponent } from './views/pages/page-recruitment/page-recruitment.component';
import { PagePrivacyPolicyComponent } from './views/pages/page-privacy-policy/page-privacy-policy.component';

const routes: Routes = [
  {
    path: '',
    component: PageHomeComponent
  },
  {
    path: 'lets-talk',
    component: PageHomeComponent
  },
  {
    path: 'about',
    component: PageAboutUsComponent
  },
  {
    path: 'team',
    component: PageAboutUsComponent
  },
  {
    path: 'service',
    component: PageOurServiceComponent
  },
  {
    path: 'outsource',
    component: PageOutsourceComponent
  },
  {
    path: 'recruitment',
    component: PageRecruitmentComponent
  },
  {
    path: 'payroll',
    component: PageOutsourceComponent
  },
  {
    path: 'general',
    component: PageRecruitmentComponent
  },
  {
    path: 'privacy-policy',
    component: PagePrivacyPolicyComponent
  },
  {
    path: '**',
    redirectTo: ''
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
