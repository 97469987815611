import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageHomeComponent } from './views/pages/page-home/page-home.component';
import { SectionHeaderComponent } from './views/sections/section-header/section-header.component';
import { SectionFooterComponent } from './views/sections/section-footer/section-footer.component';
import { SectionChatComponent } from './views/sections/section-chat/section-chat.component';
import { PageAboutUsComponent } from './views/pages/page-about-us/page-about-us.component';
import { PageOurServiceComponent } from './views/pages/page-our-service/page-our-service.component';
import { PageOutsourceComponent } from './views/pages/page-outsource/page-outsource.component';
import { PageRecruitmentComponent } from './views/pages/page-recruitment/page-recruitment.component';
import { PagePrivacyPolicyComponent } from './views/pages/page-privacy-policy/page-privacy-policy.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SectionWaComponent } from './views/sections/section-wa/section-wa.component';

@NgModule({
  declarations: [
    AppComponent,
    PageHomeComponent,
    PageAboutUsComponent,
    PageOurServiceComponent,
    PageOutsourceComponent,
    PageRecruitmentComponent,
    PagePrivacyPolicyComponent,
    SectionHeaderComponent,
    SectionFooterComponent,
    SectionChatComponent,
    SectionWaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule
  ],
  providers: [NgbActiveModal],
  bootstrap: [AppComponent]
})
export class AppModule { }
