import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {httpOptions} from '../utils/http.util';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })

export class PageService {
    constructor(public client: HttpClient) {}
    private readonly serviceUrl = environment.SDM_URL + '/api';

    pagesData(params: any): Observable<any> {
        const url = `${this.serviceUrl}/pages?name=${params}`;
        return this.client.get<any>(url, httpOptions);
    }

    letsTalk(payload: any): Observable<any> {
        const url = `${this.serviceUrl}/lets-talk`;
        return this.client.post(url, payload, httpOptions);
    }
}